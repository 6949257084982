import React from "react"
import styled from "styled-components"

import { VerticalSection, ValueProp, Grid } from "components/common"

import { device } from "utils"

const ResourcesTemplateHero = ({ title, subtitle, valueProps }) => {
  const renderValueProps = () => (
    <Grid
      col={3}
      list={valueProps}
      component={({ icon, title, subtitle, media, ctas }) => (
        <a href={media.desktop.file.url} target="_blank" rel="noreferrer">
          <ValueProp
            className="dob_card"
            media={{ desktop: icon }}
            title={title}
            titleAs="h3"
            subtitle={subtitle}
            subtitleAs="pSmall"
            ctas={ctas}
            isCard
          />
        </a>
      )}
    />
  )

  return (
    <StyledResourcesTemplateHero>
      <VerticalSection
        title={title}
        titleAs="h1"
        subtitle={subtitle}
        grid={renderValueProps}
      />
    </StyledResourcesTemplateHero>
  )
}

const StyledResourcesTemplateHero = styled.div`
  --section-padding-top: var(--sp-nav);
  --section-mobile-padding-top: var(--sp-mobile-nav);

  .gridWrap {
    ul {
      grid-template-columns: 1fr;
      justify-content: center;
      @media ${device.laptop} {
        grid-template-columns: repeat(auto-fit, minmax(auto, 405px));
      }

      .dob_card {
        transition: box-shadow 0.2s ease-in-out;
        will-change: box-shadow;
        &:hover {
          box-shadow: var(--card-shadow-hover);
        }
        .subtitle {
          --tg-subtitle-color: var(--dirt);
          --subtitle-font-weight: 400;
        }
      }
    }
  }
`

export default ResourcesTemplateHero
